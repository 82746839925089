import React from 'react';

function Footer() {
  return (
    <footer>
      <p>Built by Dhanush Thirunavukkarasu &copy; 2023</p>
    </footer>
  );
}

export default Footer;